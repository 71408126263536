import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "editchecksettlement"
  }, [_c("a-card", [_c("a-form", {
    attrs: {
      form: _vm.createForm,
      labelCol: {
        style: "width: 140px;text-align:right;margin-right:10px;"
      }
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex"
    }
  }, [_c("div", {
    staticStyle: {
      "margin-left": "100px",
      "margin-right": "20px",
      "margin-bottom": "16px"
    }
  }, [_vm._v(" 结算项 ")]), _c("div", [this.$route.query.type == 0 ? _c("div", [_vm._v(" " + _vm._s(_vm.clearingitem) + " ")]) : _c("div", _vm._l(_vm.listArr, function (item) {
    return _c("div", {
      key: item.id
    }, [_vm._v(" " + _vm._s(item.parentcategoryname) + "-" + _vm._s(item.categoryname) + "-" + _vm._s(item.brandname) + " ")]);
  }), 0)])]), _c("a-form-item", {
    attrs: {
      label: "AI鉴定/币"
    }
  }, [_c("span", [_c("a-input-number", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["aicheckmoney", {
        rules: []
      }],
      expression: "[\n                            'aicheckmoney',\n                            {\n                                rules: [],\n                            },\n                        ]"
    }],
    staticClass: "marginright",
    staticStyle: {
      width: "262px"
    },
    attrs: {
      size: "large",
      type: "number",
      min: 0,
      max: 9999999999
    }
  })], 1)]), _c("a-form-item", {
    attrs: {
      label: "AI鉴定/RMB"
    }
  }, [_c("span", [_c("a-input-number", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["aisettlement", {
        rules: []
      }],
      expression: "[\n                            'aisettlement',\n                            {\n                                rules: [],\n                            },\n                        ]"
    }],
    staticClass: "marginright",
    staticStyle: {
      width: "262px"
    },
    attrs: {
      size: "large",
      type: "number",
      min: 0,
      max: 9999999999
    }
  })], 1)]), _c("a-form-item", {
    attrs: {
      label: "普通鉴定时长/h"
    }
  }, [_c("span", [_c("a-input-number", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["checktime", {
        rules: []
      }],
      expression: "[\n                            'checktime',\n                            {\n                                rules: [],\n                            },\n                        ]"
    }],
    staticClass: "marginright",
    staticStyle: {
      width: "262px"
    },
    attrs: {
      type: "number",
      min: 0,
      size: "large",
      max: 9999999999,
      precision: _vm.precision,
      parser: function parser(value) {
        return value.replace(".", "");
      }
    }
  })], 1)]), _c("a-form-item", {
    attrs: {
      label: "普通鉴定/币"
    }
  }, [_c("span", [_c("a-input-number", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["checkmoney", {
        rules: []
      }],
      expression: "[\n                            'checkmoney',\n                            {\n                                rules: [],\n                            },\n                        ]"
    }],
    staticClass: "marginright",
    staticStyle: {
      width: "262px"
    },
    attrs: {
      type: "number",
      min: 0,
      size: "large",
      max: 9999999999
    }
  })], 1)]), _c("a-form-item", {
    attrs: {
      label: "普通鉴定结算/RMB"
    }
  }, [_c("span", [_c("a-input-number", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["settlement", {
        rules: []
      }],
      expression: "[\n                            'settlement',\n                            {\n                                rules: [],\n                            },\n                        ]"
    }],
    staticClass: "marginright",
    staticStyle: {
      width: "262px"
    },
    attrs: {
      type: "number",
      min: 0,
      size: "large",
      max: 9999999999
    }
  })], 1)]), _c("a-form-item", {
    attrs: {
      label: "是否支持快速鉴定"
    }
  }, [_c("span", [_c("a-switch", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["fastcheck", {
        initialValue: false,
        rules: []
      }],
      expression: "[\n                            'fastcheck',\n                            {\n                                initialValue: false,\n                                rules: [],\n                            },\n                        ]"
    }],
    attrs: {
      checked: _vm.isshowtime,
      "default-checked": "",
      size: "large"
    },
    on: {
      change: _vm.onChange
    }
  })], 1)]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isshowtime,
      expression: "isshowtime"
    }]
  }, [_c("a-form-item", {
    attrs: {
      label: "快速鉴定时长/min"
    }
  }, [_c("span", [_c("a-input-number", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["fastchecktime", {
        rules: []
      }],
      expression: "[\n                                'fastchecktime',\n                                {\n                                    rules: [],\n                                },\n                            ]"
    }],
    staticClass: "marginright",
    staticStyle: {
      width: "262px"
    },
    attrs: {
      type: "number",
      min: 0,
      size: "large",
      max: 9999999999,
      precision: _vm.precision,
      parser: function parser(value) {
        return value.replace(".", "");
      }
    }
  })], 1)]), _c("a-form-item", {
    attrs: {
      label: "快速鉴定/币"
    }
  }, [_c("span", [_c("a-input-number", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["fastcheckmoney", {
        rules: []
      }],
      expression: "[\n                                'fastcheckmoney',\n                                {\n                                    rules: [],\n                                },\n                            ]"
    }],
    staticClass: "marginright",
    staticStyle: {
      width: "262px"
    },
    attrs: {
      type: "number",
      min: 0,
      size: "large",
      max: 9999999999
    }
  })], 1)]), _c("a-form-item", {
    attrs: {
      label: "快速鉴定结算/RMB"
    }
  }, [_c("span", [_c("a-input-number", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["fastsettlement", {
        rules: []
      }],
      expression: "[\n                                'fastsettlement',\n                                {\n                                    rules: [],\n                                },\n                            ]"
    }],
    staticClass: "marginright",
    staticStyle: {
      width: "262px"
    },
    attrs: {
      type: "number",
      min: 0,
      size: "large",
      max: 9999999999
    }
  })], 1)])], 1)], 1), _c("div", {
    staticClass: "combinbtn"
  }, [_c("a-button", {
    staticStyle: {
      border: "none",
      "margin-right": "26px",
      "background-color": "#f5f5f5"
    },
    attrs: {
      size: "large"
    },
    on: {
      click: function click($event) {
        return _vm.handlecancel();
      }
    }
  }, [_vm._v("取消")]), _c("a-button", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["editchecksettlement:btn:submit"],
      expression: "['editchecksettlement:btn:submit']"
    }],
    attrs: {
      type: "primary",
      size: "large"
    },
    on: {
      click: function click($event) {
        return _vm.handleAdd();
      }
    }
  }, [_vm._v("保存")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };